body {
    font-family: "Poppins", sans-serif;
    font-size: .91rem !important;
    overflow-x: hidden;
    letter-spacing: 0.01em;
}


.navbarHeader li{
	margin: 0px 0px 0px 20px;
}
.navbarHeader li a{
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}
.navbarHeader li ul li{
	margin: 0px 0px 0px 0px;
}

.swiper-pagination-bullet{
    padding: 12px;
}
.swiper-pagination-bullet-active{
    background: #ffc107;
}

.section_brand{
	background-color: #F8FEF0;
}

.background-quality{
	background-color: #F8FEF0;
}


.dropdown:hover>.dropdown-menu {
	display: block;
}

.dropdown>.dropdown-toggle:active {
    pointer-events: none;
}

.headline{
	background-position: bottom right;
	background-repeat: repeat-x;
	background-size: cover;
    height: 220px;
}

.brandTab{
	
}

.nav-scroller{
	position: relative;
    z-index: 2;
    height: 60px;
    overflow-y: hidden;
}
.brandTab .nav{
	background-color: #eef4e6;
    border: 0px;
	display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}
.brandTab .nav li{
	text-align: center;
	font-weight: 500;
}
.brandTab .nav li button{
    color: #000;
    border-width: 2px;
    border-color: #eef4e6;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}
.brandTab .nav li button.active{
	border-bottom: 5px solid #FFB445;
}

.product_group{
    background-position: bottom center;
    background-repeat: repeat-x;
    background-size: contain;
    height: 300px;
    display: block;
}

.swiperProductGroup .swiper-slide{
    width: 20%;
}

.section_channel{
	background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../../docs/sales_channel-background.jpg);
}

.section_quality{
	background-color: #FFB445;
}


.footer_top{
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../../docs/footer_top.png);
}

.swiper.homeSlider {
	width: 100%;
	height: 100%;
}
.swiper.homeSlider .swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.swiper.homeSlider .swiper-slide .slide-content{
	width: 90%;
    position: absolute;
    text-align: left;
}
.swiper.homeSlider .swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.swiper.homeSlider .autoplay-progress {
	position: absolute;
	right: 16px;
	bottom: 16px;
	z-index: 10;
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	color: var(--swiper-theme-color);
}
.swiper.homeSlider .autoplay-progress svg {
	--progress: 0;
	position: absolute;
	left: 0;
	top: 0px;
	z-index: 10;
	width: 100%;
	height: 100%;
	stroke-width: 4px;
	stroke: var(--swiper-theme-color);
	fill: none;
	stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
	stroke-dasharray: 125.6;
	transform: rotate(-90deg);
}

.swiper.brandSwiper {
	width: 100%;
	height: 100%;
}
.swiper.brandSwiper .swiper-slide {
	width: auto;
}
.swiper.facilitySwiper .swiper-slide {
	width: auto;
}



@media (max-width: 768px) {
	.swiper.homeSlider .swiper-slide {
        height: 660px;
        display: block;
        background: #063830;
	}
	.swiper.homeSlider .swiper-slide img {
		height: 300px;
        object-fit: cover;
        object-position: center;
	}
	.swiper.homeSlider .swiper-slide .slide-content{
        width: 100%;
        text-align: left;
        position: initial;
        height: 200px;
        display: block;
        padding: 2rem;
	}
	.swiperProductGroup .swiper-slide{
		width: 100%;
	}
	.section_quality{
		background-image: none;
	}
}